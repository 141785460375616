import { observer } from "mobx-react-lite";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from "../LanguageSwitcher";

function Banner() {
  const { t } = useTranslation();


  return (
    <>
      <section id="index-banner">
        <div className="content">
          <Container>
            <div className="d-flex justify-content-between align-items-center">
              <Link to="/" className="brand">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlSpace="preserve"
                  style={{
                    shapeRendering: "geometricPrecision",
                    textRendering: "geometricPrecision",
                    fillRule: "evenodd",
                    clipRule: "evenodd",
                  }}
                  viewBox="0 0 31750 31750"
                >
                  <path
                    d="m11567.97 16311.13 3789.6-709.17 919.54-2525.58 2747.49 2981.12c-383.74 121.32-5031.71 1024.47-5852.25 1190.41-698.73 141.31-1324.83 202.12-1833.65 557.45-520.49 363.5-629.05 713.81-860.14 1492.23-546.05 1773.6-1191.24 3625.26-1661.63 5410.09l3803.2-779.39c158-567.08 366.14-1123.6 551.15-1677.51 542.53-1624.42 262.75-1317.14 2760.9-1765.32 1243.77-223.15 4751.37-735.57 5474.45-1314.02 417.01-333.6 1230.39-2516.6 1405.64-3122.76 322.29-1114.64-1452.88-2846.16-1507.57-2914.36l-6256.44-7286.87-3480.29 10463.68z"
                  />
                </svg>
                {t('brandName')}
              </Link>
              <ul className="d-flex list-unstyled align-items-center mb-0">
                <LanguageSwitcher themeStyle={"light"} />
                <li className="ms-3">
                  <a href="#footer" className="btn btn-outline-light desktop-show">
                    {t('DownloadApp')}
                  </a>
                  <a href="#footer" className="btn btn-outline-light mobile-show">
                    {t('DownloadApp')}
                  </a>
                </li>
              </ul>
            </div>

            <Row className="pt-5">
              <Col>
                <img
                  className="phone-image"
                  src="/images/index-banner-phone.png"
                  alt=""
                />
              </Col>
              <Col lg={7}>
                <h1 dangerouslySetInnerHTML={{ __html: t('bannerTitle') }}>

                </h1>
                <p className="my-5">
                  {t('bannerSubtitle')}
                </p>
                <a className="btn btn-light btn-lg" href="#footer">
                  {t('ctaBtnLabel')}
                </a>
              </Col>
            </Row>

            <h5 className="text-center text-white" style={{ fontSize: 15, opacity: '.8', fontWeight: 300 }}>
              <svg className="mb-3" width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                {/* ... (SVG path) ... */}
              </svg> <br />
              {t('paidaxLicense')}{' '}
              <a href="/docs/Regulated_Activities_Licence.pdf" className="text-white" target="_blank" >
                AFSA-G-LA-2023-0007
              </a>
            </h5>
          </Container>
        </div>
        <div className="content-light"></div>
      </section>
    </>
  );
}

export default observer(Banner);
