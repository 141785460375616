import React from 'react';
import { isIOS, isAndroid } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import config from '../config'; 


const RedirectStoreWithoutReferral = () => {
    const { t } = useTranslation();
    const { appStoreUrl, playStoreUrl, defaultUrl } = config.urls;

    React.useEffect(() => {
        if (isIOS) {
            window.location.href = appStoreUrl;
        } else if (isAndroid) {
            window.location.href = playStoreUrl; 
        } else {
            window.location.href = defaultUrl;
        }
    }, [appStoreUrl, playStoreUrl, defaultUrl]);

    return (
        <div><p>{t('Redirecting')}...</p></div>
    );
};

export default RedirectStoreWithoutReferral;