import axios from 'axios';
import { observer } from 'mobx-react-lite';
import React, { useState, useEffect } from 'react';
import { Button, Container, FloatingLabel, Form, Spinner } from 'react-bootstrap';
import ReactInputMask from 'react-input-mask';
import { useTranslation } from 'react-i18next';

function ContactUs() {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const isNameValid = name.trim().length > 0;
    const isPhoneValid = phone.replace(/\D/g, '').length === 11;
    setIsFormValid(isNameValid && isPhoneValid);
  }, [name, phone]);

  const handleForm = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const jsonObject = {
      name: name,
      phone: phone.replace('+', '')
    };

    axios.post(
      'https://script.google.com/macros/s/AKfycbxSMiKZ3o8Sypd-EGc79YhyceHsCCkrR5LkTIduHZJ23Crz0y9k5eB6-R_PDZp4W-c/exec',
      JSON.stringify(jsonObject),
    ).then((response) => {
      setIsLoading(false);
      if (response.status === 200) {
        alert(t('contactUs.successMessage'));
        setName('');
        setPhone('');
      } else {
        alert(t('contactUs.errorMessage'));
      }
    }).catch(() => {
      setIsLoading(false);
      alert(t('contactUs.errorMessage'));
    });
  };

  return (
    <section id="contact-us">
      <Container>
        <div className="contact-us-container">
          <Form className='contact-us-form' onSubmit={handleForm}>
            <h2>{t('contactUs.pageTitle')}</h2>
            <FloatingLabel controlId="floatingName" label={t('contactUs.nameLabel')}>
              <Form.Control
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FloatingLabel>
            <FloatingLabel controlId="floatingPhone" label={t('contactUs.phoneLabel')}>
              <ReactInputMask
                mask="+7 (999) 999-99-99"
                maskPlaceholder=""
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              >
                {() => <Form.Control type="text" />}
              </ReactInputMask>
            </FloatingLabel>
            <Button size='lg' type='submit' disabled={!isFormValid || isLoading}>
              {isLoading ? <Spinner animation="border" size="sm" /> : t('contactUs.sendButton')}
            </Button>
          </Form>
        </div>
      </Container>
    </section>
  );
}

export default observer(ContactUs);
