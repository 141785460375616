import React, { useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { Container } from 'react-bootstrap';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

function SwiperInstruction() {
  const { store } = useContext(Context);
  const { t } = useTranslation();

  var list = [
    {
      title: t('swiperInstruction.registrationTitle'),
      description: t('swiperInstruction.registrationDescription'),
      img: '/images/swiper-slide-1.png'
    },
    {
      title: t('swiperInstruction.interfaceTitle'),
      description: t('swiperInstruction.interfaceDescription'),
      img: '/images/swiper-slide-2.png'
    },
    {
      title: t('swiperInstruction.learnInvestTitle'),
      description: t('swiperInstruction.learnInvestDescription'),
      img: '/images/swiper-slide-3.png'
    },
    {
      title: t('swiperInstruction.easyWithdrawTitle'),
      description: t('swiperInstruction.easyWithdrawDescription'),
      img: '/images/swiper-slide-4.png'
    },
  ];

  return (
    <section id="swiper-instruction">
      <Container>
        <Swiper
          direction={'vertical'}
          slidesPerView={1}
          pagination={{
            clickable: true,
          }}
          mousewheel={true}
          navigation
          modules={[Pagination, Navigation]}
          className={`desktop-show ${store.lang}`}
        >
          {list.map((m, i) => {
            return (
              <SwiperSlide key={i}>
                <div className="swiper-instruction-slide">
                  <div className="row">
                    <div className="col d-flex flex-column justify-content-center text-start">
                      <h4 className="mb-4">{m.title}</h4>
                      <p>{m.description}</p>
                    </div>
                    <div className="col">
                      <img src={m.img} alt="" />
                    </div>
                    <div className="col"></div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <Swiper
          direction={'horizontal'}
          spaceBetween={20}
          slidesPerView={1}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mobile-show"
        >
          {list.map((m, i) => {
            return (
              <SwiperSlide key={i}>
                <div className="swiper-instruction-slide">
                  <div className="row">
                    <div className="col d-flex flex-column justify-content-center text-start">
                      <h4 className="mb-4">{m.title}</h4>
                      <p>{m.description}</p>
                    </div>
                    <div className="col">
                      <img src={m.img} alt="" />
                    </div>
                    <div className="col"></div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Container>
    </section>
  );
}

export default observer(SwiperInstruction);
