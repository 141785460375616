import { observer } from "mobx-react-lite";
import React from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

function CreatePortfolio() {
  const { t } = useTranslation();

  return (
    <section id="create-portfolio">
      <Container>
        <div className="row">
          <div className="col-md-6 d-flex align-items-center">
            <div className="create-portfolio-content">
              <h3>{t('createPortfolio.title')}</h3>
              <p>{t('createPortfolio.content')}</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="create-portfolio-image">
              <img src="/images/companies.png" alt="" />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default observer(CreatePortfolio);
