// DownloadAppPage.js
import React from 'react';
import { Col, Container,} from 'react-bootstrap'
import RedirectStore from '../utils/RedirectStore'; 
import { useTranslation } from 'react-i18next';

const DownloadAppPage = () => {
    const { t } = useTranslation();
    return (
        <div className="content d-flex justify-content-between align-items-center">
            <Container>
                <Col style={{ margin: '60px 0' }}>
                    <p>{t('DownloadApp')}</p>
                    <RedirectStore />
                </Col>
            </Container>
        </div>
    );
};

export default DownloadAppPage;