import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// Загрузка переводов
const resources = {
    en: { translation: require('./locale/en.json') },
    ru: { translation: require('./locale/ru.json') },
    kz: { translation: require('./locale/kz.json') }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: localStorage.getItem('i18nextLng') || 'ru',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;

