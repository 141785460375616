import React, { createContext } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "swiper/css";
import "swiper/css/pagination";
import './i18n';

import "bootstrap/dist/css/bootstrap.min.css";
import "./style/style.css";
import Store from "./store/store";
export const priceToString = (price) => {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
const root = ReactDOM.createRoot(document.getElementById("root"));

export const Context = createContext(null);

root.render(
  <Context.Provider
    value={{
      store: new Store(),
    }}
  >
    <App />
  </Context.Provider>
);
