import React from 'react'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from "swiper/react";

function Partners() {
    const { t } = useTranslation()
    const list = [
        {
            img: '/images/partners-logo/afsa.png',
            title: 'afsa'
        },
        {
            img: '/images/partners-logo/aifc.png',
            title: 'aifc'
        },
        {
            img: '/images/partners-logo/astana-hub.png',
            title: 'astana-hub'
        },
        {
            img: '/images/partners-logo/its.svg',
            title: 'its'
        },
    ]

    return (
        <section id="partners">

            <h3 className='text-center mb-5 fw-bold'>{t('partners.title')}</h3>

            <Container>
                <Swiper
                    spaceBetween={0}
                    slidesPerView={4}
                    className={'desktop-show'}
                >
                    {
                        list.map((item, i) => {
                            return <SwiperSlide key={i}><div  className='partner-list-item'>
                                <img src={item.img} alt={item.title} className={item.title === 'aie' ? 'aix' : ''} />
                            </div></SwiperSlide>
                        })
                    }

                </Swiper>

                <Swiper
                    spaceBetween={20}
                    slidesPerView={3}
                    className={'mobile-show'}
                >
                    {
                        list.map((item, i) => {
                            return <SwiperSlide key={i}><div  className='partner-list-item'>
                                <img src={item.img} alt={item.title} className={item.title === 'aie' ? 'aix' : ''} />
                            </div></SwiperSlide>
                        })
                    }

                </Swiper>

            </Container>
        </section>
    )
}

export default Partners