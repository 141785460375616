// import React from 'react';

// const PrivacyPolicy = () => {
//     return (
//         <div>
//             <h1>Privacy Policy</h1>
//             <p>This is the privacy policy page.</p>
//             {/* Add your privacy policy content here */}
//         </div>
//     );
// };

// export default PrivacyPolicy;


import React from 'react'
import Nav from '../components/Nav'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import LanguageSwitcher from '../components/LanguageSwitcher'
import { useTranslation } from 'react-i18next'

function PrivacyPolicy() {
    const { t, i18n } = useTranslation()
    const currentLanguage = i18n.language;

    switch (currentLanguage) {
        case 'en':
            return (
                <>
                    <div className="legal-center">
                        <div className="content">
                            <Container>
                                <div className="d-flex justify-content-between align-items-center">
                                    <Link to="/" className="brand">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlSpace="preserve"
                                            style={{
                                                shapeRendering: "geometricPrecision",
                                                textRendering: "geometricPrecision",
                                                fillRule: "evenodd",
                                                clipRule: "evenodd",
                                            }}
                                            viewBox="0 0 31750 31750"
                                        >
                                            <path
                                                d="m11567.97 16311.13 3789.6-709.17 919.54-2525.58 2747.49 2981.12c-383.74 121.32-5031.71 1024.47-5852.25 1190.41-698.73 141.31-1324.83 202.12-1833.65 557.45-520.49 363.5-629.05 713.81-860.14 1492.23-546.05 1773.6-1191.24 3625.26-1661.63 5410.09l3803.2-779.39c158-567.08 366.14-1123.6 551.15-1677.51 542.53-1624.42 262.75-1317.14 2760.9-1765.32 1243.77-223.15 4751.37-735.57 5474.45-1314.02 417.01-333.6 1230.39-2516.6 1405.64-3122.76 322.29-1114.64-1452.88-2846.16-1507.57-2914.36l-6256.44-7286.87-3480.29 10463.68z"
                                            />
                                        </svg>
                                        {t('brandName')}
                                    </Link>
                                    <ul className="d-flex list-unstyled align-items-center mb-0">
                                        <LanguageSwitcher themeStyle={"light"} />
                                        <li className="ms-3">
                                            <a href="#contact-us" className="btn btn-outline-light desktop-show">
                                                {t('ctaBtnText')}
                                            </a>
                                            <a href="#contact-us" className="btn btn-outline-light mobile-show">
                                                {t('ctaBtnLabel')}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <h1 className='text-white fw-bold my-5'>{t('footer.privacyPolicy')}</h1>
                            </Container>
                        </div>
                        <Container>
                            <Col style={{ margin: '60px 0' }}>
                                <p>This Privacy Policy governs the manner in which Paidax Limited, an online broker company, collects, secures, uses, retains, discloses, and processes personal information. It applies to individuals who access and use Paidax's website, mobile app, and engage with its services (collectively referred to as "Services"). Throughout this document, the terms "customer" and "you" are used to refer to individuals who interact with the Services.</p>
        
                                <h4>Personal Information We Collect</h4>
                                <p>The personal information collected by Paidax Limited is diverse and depends on your interactions with us, the
                                specific Services you sign up for or use, and the choices you make. The sources of this information include direct submissions, automatic collection and third-party data sources. While you have the option to decline providing certain personal information, please note that certain information is necessary for the proper functioning of our Services.</p>
                                
                                <h4>Personal Information You Provide to Us</h4>
                                <ul>
                                    <li>Contact Information: This includes your name, email address, home address, and phone number.</li>
                                    <li>Demographic Information: Details such as date of birth and marital status.</li>
                                    <li>Investment Profile: Information related to your tax status, investment goals, and investing experience.</li>
                                    <li>Employment Information: Data about your employment status, job title, employer information, and salary details.</li>
                                    <li>Financial Account Information: Details like bank account number, routing numbers, and credit/debit card information.</li>
                                    <li>Transactions Information: Information about the transactions you conduct using our Services, including notional value, assets traded, and timestamps.</li>
                                    <li>Your Contacts: With your explicit permission, we may access and store names and contact information from your address book.</li>
                                    <li>Account and Profile Information: When you create an account with Paidax, you provide account access information, an optional profile picture, and a profile bio.</li>
                                    <li>Audio or Electronic Information: Records of your communications with us.</li>
                                    <li>Community Engagement Information: When you participate in the Paidax community, personal information is collected as you send and receive messages, share content, react to others' content, and follow other Paidax customers or companies on the platform.</li>
                                </ul>
                                
                                <h4>Sensitive Personal Information</h4>
                                <p>Certain Services may require the collection of sensitive personal information, including:</p>
                                <ul>
                                    <li>Government ID: Such as government-issued identifiers like your identification card, driver’s license, and passport number.</li>
                                    <li>Account Access Information: Such as a username or account number.</li>
                                    <li>Biometric Information: Facial images from your identification card or selfie photographs.</li>
                                </ul>
                                
                                <h4>Personal Information We Collect Automatically</h4>
                                <p>As is typical of online platforms, Paidax may automatically collect personal information based on your internet or other electronic network activity when you use our Services. This includes:</p>
                                <ul>
                                    <li>Usage Information: Details about your engagement with our Services, such as pages viewed, features used, notifications seen, and visit dates and times.</li>
                                    <li>Location Information: We may infer your general geographic location using your internet protocol (IP) address.</li>
                                    <li>Device Information: Information about the device and software you use to access our Services.</li>
                                </ul>
                                
                                <h4>Personal Information We Receive from Other Sources</h4>
                                <p>Paidax Limited may receive information about you from other sources, including:</p>
                                <ul>
                                    <li>Third-Party Partners: Information obtained from third-party partners for the operation and maintenance of our Services, including identity verification and fraud prevention.</li>
                                    <li>Service Providers: Entities that collect or provide information on our behalf.</li>
                                </ul>
                                
                                <h4>Purpose of Use</h4>
                                <p>Paidax Limited uses the personal information collected for various purposes, including:</p>
                                <ul>
                                    <li>Service delivery: Providing, maintaining, and delivering our Services, including facilitating transactions, ensuring payment security, and performing identity verification.</li>
                                    <li>Business operations: Operating our business, including billing and accounting.</li>
                                    <li>Service improvement, analysis, development, and research: Enhancing our Services, analyzing user interactions, developing new features, and conducting research to improve internal operations.</li>
                                    <li>Customer support: Providing customer support and responding to inquiries.</li>
                                </ul>
                                
                                <h4>Personal Information We Disclose</h4>
                                <p>Paidax Limited may disclose personal information as necessary for completing transactions, providing Services, or with your consent. Disclosures include:</p>
                                <ul>
                                    <li>Service providers: Providing personal information to vendors or agents working on our behalf.</li>
                                    <li>Financial service companies & payment processors : Disclosing payment and transactional data to banks and entities for payment processing and fraud prevention.</li>
                                    <li>Parties to corporate transactions: In the context of mergers, financing, acquisitions, bankruptcies, dissolutions, or transfers of business assets.</li>
                                    <li>Legal and law enforcement: Accessing, disclosing, and preserving personal information when required to comply with applicable law or respond to valid legal processes.</li>
                                </ul>
                                
                                <h4>Public Information</h4>
                                <p>Certain information may be publicly disclosed through our Services, including:</p>
                                <ul>
                                    <li>Profile, Portfolio, and Transactions: Information viewable by other customers.</li>
                                    <li>Posts: Content posted to public feeds, visible to other customers.</li>
                                    <li>Social Networks and Other Online Services: Information posted to external social networking services.</li>
                                </ul>
                                
                                <h4>Information from Cookies and Similar Technologies</h4>
                                <p>Paidax Limited employs cookies, web beacons, mobile analytics, advertising IDs, and similar technologies to operate Services and collect data, including usage data, identifiers, and device information.</p>
                                
                                <h4>How We Protect Personal Information</h4>
                                <p>Paidax Limited takes reasonable steps to protect personal information from unauthorized access, use, disclosure, alteration, and destruction. Users are encouraged to use strong passwords and avoid sharing them.</p>
                                
                                <h4>How We Retain Personal Information</h4>
                                <p>Personal information is retained for as long as necessary to provide Services, fulfill transactions, comply with legal obligations, resolve disputes, enforce agreements, and for other legitimate business purposes. Retention periods may vary based on factors such as customer expectations, data sensitivity, automated deletion controls, and legal obligations.</p>
                                
                                <h4>Changes to this Privacy Policy</h4>
                                <p>This Privacy Policy will be updated periodically to reflect changes in Services, data processing practices, or applicable laws. The "Effective Date" will be revised with each update. Users are encouraged to regularly review this Privacy Policy.</p>
                                
                                <h4>Contact Information</h4>
                                <p>If you have questions, comments, or concerns about our processing activities, please email us at <a style={{ textDecoration: 'none', color: 'grey' }} href="mailto:info@paidax.kz"> info@paidax.kz</a>. Our legal address is Astana, Esil district, 5, Dostyk, office 125, Z05H9M1.</p>
                            </Col>
                        </Container>
                    </div>
                </>
            )
        case 'ru':
            return (
                <>
                    <div className="legal-center">
                        <div className="content">
                            <Container>
                                <div className="d-flex justify-content-between align-items-center">
                                    <Link to="/" className="brand">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlSpace="preserve"
                                            style={{
                                                shapeRendering: "geometricPrecision",
                                                textRendering: "geometricPrecision",
                                                fillRule: "evenodd",
                                                clipRule: "evenodd",
                                            }}
                                            viewBox="0 0 31750 31750"
                                        >
                                            <path
                                                d="m11567.97 16311.13 3789.6-709.17 919.54-2525.58 2747.49 2981.12c-383.74 121.32-5031.71 1024.47-5852.25 1190.41-698.73 141.31-1324.83 202.12-1833.65 557.45-520.49 363.5-629.05 713.81-860.14 1492.23-546.05 1773.6-1191.24 3625.26-1661.63 5410.09l3803.2-779.39c158-567.08 366.14-1123.6 551.15-1677.51 542.53-1624.42 262.75-1317.14 2760.9-1765.32 1243.77-223.15 4751.37-735.57 5474.45-1314.02 417.01-333.6 1230.39-2516.6 1405.64-3122.76 322.29-1114.64-1452.88-2846.16-1507.57-2914.36l-6256.44-7286.87-3480.29 10463.68z"
                                            />
                                        </svg>
                                        {t('brandName')}
                                    </Link>
                                    <ul className="d-flex list-unstyled align-items-center mb-0">
                                        <LanguageSwitcher themeStyle={"light"} />
                                        <li className="ms-3">
                                            <a href="#contact-us" className="btn btn-outline-light desktop-show">
                                                {t('ctaBtnText')}
                                            </a>
                                            <a href="#contact-us" className="btn btn-outline-light mobile-show">
                                                {t('ctaBtnLabel')}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <h1 className='text-white fw-bold my-5'>{t('footer.privacyPolicy')}</h1>
                            </Container>
                        </div>
                        
                        <Container>
                            <Col style={{ margin: '60px 0' }}>
                                <p>Данная Политика Конфиденциальности регулирует порядок сбора, защиты, использования, сохранения, раскрытия и обработки персональной информации компанией Paidax Limited, онлайн-брокером. Она применяется к лицам, использующим веб-сайт, мобильное приложение и взаимодействующим с услугами компании (в дальнейшем "Услуги"). В этом документе термины "клиент" и "вы" используются для обозначения лиц, взаимодействующих с Услугами.</p>
        
                                <h4>Личная Информация, Которую Мы Собираем</h4>
                                <p>Личная информация, собираемая Paidax Limited, разнообразна и зависит от ваших взаимодействий с нами, конкретных Услуг, которые вы используете, и ваших
                                выборов. Источниками этой информации могут быть прямые предоставления, автоматический сбор и данные от сторонних источников. Вы можете отказаться предоставить определенную личную информацию, но обратите внимание, что некоторая информация необходима для нормального функционирования наших Услуг.</p>
                                
                                <h4>Личная Информация, Которую Вы Нам Предоставляете</h4>
                                <ul>
                                    <li>Контактная Информация: Включает ваше имя, адрес электронной почты, домашний адрес и номер телефона.</li>
                                    <li>Демографическая Информация: Детали, такие как дата рождения и семейное положение.</li>
                                    <li>Инвестиционный Профиль: Информация о вашем налоговом статусе, инвестиционных целях и опыте инвестирования.</li>
                                    <li>Информация о Занятости: Данные о вашем статусе занятости, должности, информации о работодателе и деталях заработной платы.</li>
                                    <li>Информация о Финансовых Счетах: Детали, такие как номер банковского счета, маршрутные номера и информация о кредитных/дебетовых картах.</li>
                                    <li>Информация о Транзакциях: Детали транзакций, включая номинальную стоимость, активы, совершенные сделки и метки времени.</li>
                                    <li>Ваши Контакты: С вашего разрешения мы можем получить и хранить имена и контактную информацию из вашей адресной книги.</li>
                                    <li>Информация об Аккаунте и Профиле: При создании аккаунта на Paidax, вы предоставляете информацию об учете, необязательное изображение профиля и биографию профиля.</li>
                                    <li>Аудио или Электронная Информация: Записи ваших коммуникаций с нами.</li>
                                    <li>Информация об Участии в Сообществе: При участии в сообществе Paidax собирается информация при отправке и получении сообщений, обмене контентом, реагировании на контент других и следовании другим клиентам или компаниям на платформе.</li>
                                </ul>
                                
                                <h4>Конфиденциальная Информация</h4>
                                <p>Некоторые Услуги могут потребовать сбора конфиденциальной информации, включая:</p>
                                <ul>
                                    <li>Паспортные Данные: Такие как номера вашего удостоверения личности, водительских прав и паспорта.</li>
                                    <li>Информация об Учетной Записи: Такие как имя пользователя или номер счета.</li>
                                    <li>Биометрическая Информация: Изображения лица с вашего удостоверения личности или селфи.</li>
                                </ul>
                                
                                <h4>Личная Информация, Которую Мы Автоматически Собираем</h4>
                                <p>Как и многие онлайн-платформы, Paidax автоматически собирает личную информацию на основе ваших действий в сети при использовании наших Услуг. Это включает:</p>
                                <ul>
                                    <li>Информацию об Использовании: Детали ваших взаимодействий с Услугами, такие как просмотр страниц, использование функций, уведомлений и даты и времени посещений.</li>
                                    <li>Информацию о Местоположении: Мы можем предполагать ваше общее географическое местоположение с использованием вашего IP-адреса.</li>
                                    <li>Информацию о Устройстве: Информацию о устройстве и программном обеспечении, которое вы используете для доступа к нашим Услугам.</li>
                                </ul>
                                
                                <h4>Личная Информация, Которую Мы Получаем из Других Источников</h4>
                                <p>Paidax Limited может получать информацию о вас из других источников, включая:</p>
                                <ul>
                                    <li>Сторонних Партнеров: Информацию, полученную от сторонних партнеров для обеспечения функционирования и поддержания наших Услуг, включая верификацию личности и предотвращение мошенничества.</li>
                                    <li>Поставщиков Услуг: Организации, собирающие или предоставляющие информацию от нашего имени.</li>
                                </ul>
                                
                                <h4>Цель Использования</h4>
                                <p>Paidax Limited использует собранную личную информацию для различных целей, включая:</p>
                                <ul>
                                    <li>Предоставление Услуг: Поддержание, предоставление и доставка наших Услуг, включая улучшение транзакций, обеспечение безопасности платежей и проведение верификации личности.</li>
                                    <li>Операции Бизнеса: Управление бизнесом, включая выставление счетов и бухгалтерию.</li>
                                    <li>Улучшение, Анализ, Разработка и Исследование Услуг: Улучшение Услуг, анализ взаимодействия пользователей, разработка новых функций и проведение исследований для улучшения внутренних операций.</li>
                                    <li>Поддержка Клиентов: Предоставление поддержки клиентам и ответы на вопросы.</li>
                                </ul>
                                
                                <h4>Раскрытие Личной Информации</h4>
                                <p>Paidax Limited может раскрывать личную информацию при необходимости для завершения транзакций, предоставления Услуг или с вашего согласия. Раскрытия включают в себя:</p>
                                <ul>
                                    <li>Поставщики Услуг: Предоставление личной информации поставщикам или агентам, действующим от имени компании.</li>
                                    <li>Финансовые Компании и Обработчики Платежей: Раскрытие данных о платежах и транзакциях банкам и другим организациям для обработки платежей и предотвращения мошенничества.</li>
                                    <li>Стороны Корпоративных Сделок: В контексте слияний, финансирования, приобретений, банкротства, ликвидации или передачи бизнеса или его части.</li>
                                    <li>Юридические и Правоохранительные Органы: Доступ, раскрытие и сохранение личной информации в случаях, когда это необходимо для соблюдения применимого закона или реагирования на законные запросы, включая запросы от других правительственных органов.</li>
                                    <li>Публичная Информация: Определенная информация может быть публично раскрыта через наши Услуги.</li>
                                </ul>
                                
                                <h4>Информация из Файлов Cookies и Аналогичных Технологий</h4>
                                <p>Paidax Limited использует файлы cookies, веб-бейджи, мобильные аналитические идентификаторы, рекламные идентификаторы и аналогичные технологии для обеспечения функционирования Услуг и сбора данных, включая данные об использовании, идентификаторы и информацию об устройстве.</p>
                                
                                <h4>Как Мы Защищаем Личную Информацию</h4>
                                <p>Paidax Limited предпринимает разумные меры для защиты личной информации от несанкционированного доступа, использования, раскрытия, изменения и уничтожения. Рекомендуем пользователям использовать надежные пароли и избегать их передачи третьим лицам.</p>
                                
                                <h4>Как Мы Сохраняем Личную Информацию</h4>
                                <p>Личная информация сохраняется столько, сколько необходимо для предоставления Услуг, выполнения транзакций, соблюдения юридических обязательств, разрешения споров, принудительного соблюдения соглашений и для других законных целей бизнеса. Сроки хранения могут варьироваться в зависимости от ожиданий клиента, чувствительности данных, наличия автоматизированных средств удаления данных и юридических обязательств.</p>
                                
                                <h4>Изменения в настоящей Политике Конфиденциальности</h4>
                                <p>Настоящая Политика Конфиденциальности будет периодически обновляться для отражения изменений в Услугах, практиках обработки данных или применимых законах. "Дата Вступления в Силу" будет изменяться при каждом обновлении. Рекомендуем пользователям регулярно просматривать эту Политику Конфиденциальности.</p>
                                
                                <h4>Контактная Информация</h4>
                                <p>По вопросам, комментариям или обеспокоенностям относительно наших действий по обработке данных, пожалуйста, направляйте запросы на наш электронный адрес: <a style={{ textDecoration: 'none', color: 'grey' }} href="mailto:info@paidax.kz"> info@paidax.kz</a>. Наш юридический адрес: Астана, район Есиль, 5, Достык, офис 125, Z05H9M1.</p>
                            </Col>
                        </Container>
                    </div>
                </>
            )
        case 'kz':
            return (
                <>
                    <div className="legal-center">
                        <div className="content">
                            <Container>
                                <div className="d-flex justify-content-between align-items-center">
                                    <Link to="/" className="brand">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlSpace="preserve"
                                            style={{
                                                shapeRendering: "geometricPrecision",
                                                textRendering: "geometricPrecision",
                                                fillRule: "evenodd",
                                                clipRule: "evenodd",
                                            }}
                                            viewBox="0 0 31750 31750"
                                        >
                                            <path
                                                d="m11567.97 16311.13 3789.6-709.17 919.54-2525.58 2747.49 2981.12c-383.74 121.32-5031.71 1024.47-5852.25 1190.41-698.73 141.31-1324.83 202.12-1833.65 557.45-520.49 363.5-629.05 713.81-860.14 1492.23-546.05 1773.6-1191.24 3625.26-1661.63 5410.09l3803.2-779.39c158-567.08 366.14-1123.6 551.15-1677.51 542.53-1624.42 262.75-1317.14 2760.9-1765.32 1243.77-223.15 4751.37-735.57 5474.45-1314.02 417.01-333.6 1230.39-2516.6 1405.64-3122.76 322.29-1114.64-1452.88-2846.16-1507.57-2914.36l-6256.44-7286.87-3480.29 10463.68z"
                                            />
                                        </svg>
                                        {t('brandName')}
                                    </Link>
                                    <ul className="d-flex list-unstyled align-items-center mb-0">
                                        <LanguageSwitcher themeStyle={"light"} />
                                        <li className="ms-3">
                                            <a href="#contact-us" className="btn btn-outline-light desktop-show">
                                                {t('ctaBtnText')}
                                            </a>
                                            <a href="#contact-us" className="btn btn-outline-light mobile-show">
                                                {t('ctaBtnLabel')}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <h1 className='text-white fw-bold my-5'>{t('footer.privacyPolicy')}</h1>
                            </Container>
                        </div>
                        
                        <Container>
                            <Col style={{ margin: '60px 0' }}>
                                <p>Бұл Құпиялық саясаты Paidax Limited онлайн брокерінің жеке ақпаратты жинауын, қорғауын, пайдалануын, сақтауын, ашуын және өңдеуін реттейді. Ол веб-сайтты, мобильді қосымшаны пайдаланатын және компанияның қызметтерімен (бұдан әрі «Қызметтер» деп аталады) өзара әрекеттесетін жеке тұлғаларға қатысты. Бұл құжатта «клиент» пен «сіз» терминдері Қызметтермен өзара әрекеттесетін жеке тұлғаларға қарата айтылады.</p>
        
                                <h4>Біз жинайтын жеке ақпарат</h4>
                                <p>Paidax Limited жинайтын жеке ақпарат алуан түрлі және бізбен өзара әрекеттесуіңізге, сіз пайдаланатын арнайы қызметтерге және сіз жасаған таңдауларға байланысты. Бұл ақпарат көздеріне тіке жіберу, автоматты жинау және үшінші тарап көздерінен алынған деректер кіруі мүмкін. Сіз белгілі бір жеке ақпаратты бермеуді таңдай аласыз, бірақ кейбір ақпарат біздің Қызметтердің дұрыс жұмыс істеуі үшін қажет екенін ескеріңіз.</p>
                                
                                <h4>Сіз бізге беретін жеке ақпарат</h4>
                                <ul>
                                    <li>Байланыс Ақпараты: атыңызды, электрондық поштаны, үй мекен-жайыңызды және телефон нөміріңізді қамтиды.</li>
                                    <li>Демографиялық ақпарат: туған күні мен отбасылық күйі сияқты мәліметтер.</li>
                                    <li>Инвестициялық профиль: салық мәртебесі, инвестициялаудағы мақсаттар мен инвестициялық тәжірибе туралы ақпарат.</li>
                                    <li>Жұмыспен қамту туралы ақпарат: сіздің жұмыс орныңыз, лауазымыңыз, жұмыс беруші туралы ақпарат пен жалақы туралы мәліметтер.</li>
                                    <li>Қаржылық шот туралы ақпарат: банктік шот нөмірі, бағыттау нөмірлері және несие/дебеттік карта ақпараты сияқты мәліметтер.</li>
                                    <li>Транзакция туралы ақпарат: номиналды құнын, активтерін, жасалған сауда-саттықты және уақыт белгілері сияқты транзакция мәліметтері.</li>
                                    <li>Контактілеріңіз: Сіздің рұқсатыңызбен мекен-жай кітапшаңыздан аттар мен байланыс ақпаратын алып, сақтай аламыз.</li>
                                    <li>Тіркелгі мен профиль ақпараты: Paidax жүйесінде тіркелгінде, тіркелгі ақпаратын, қосымша профиль суретін және профиль
                                    өмірбаянын бересіз.</li>
                                    <li>Аудио немесе электронды ақпарат: бізбен байланысыңыздың жазбалары.</li>
                                    <li>Қауымдастыққа қатысу туралы ақпарат: Paidax қауымдастығына қатысқан кезде ақпарат сіз хабарларды жіберген және қабылдаған кезде, мазмұнды бөліскенде, басқалардың мазмұнына жауап бергенде және платформадағы басқа тұтынушыларды немесе бизнесті бақылағанда жиналады.</li>
                                </ul>
                                
                                <h4>Құпия ақпарат</h4>
                                <p>Кейбір қызметтер құпия ақпаратты жинауды талап етуі мүмкін, соның ішінде:</p>
                                <ul>
                                    <li>Паспортные Данные: Такие как номера вашего удостоверения личности, водительских прав и паспорта.</li>
                                    <li>Есептік жазба туралы ақпарат: пайдаланушы аты немесе тіркелгі нөмірі.</li>
                                    <li>Биометриялық ақпарат: жеке куәлігіңіздегі немесе селфидегі бет суреттері.</li>
                                </ul>
                                
                                <h4>Біз автоматты түрде жинайтын жеке ақпарат</h4>
                                <p>Көптеген онлайн платформалар сияқты, Paidax қызметтерімізді пайдаланған кезде онлайн әрекеттеріңізге негізделген жеке ақпаратты автоматты түрде жинайды. Бұған мыналар кіреді:</p>
                                <ul>
                                    <li>Пайдалану туралы ақпарат: Қаралған беттер, пайдаланылған мүмкіндіктер, хабарландырулар және кіру күндері мен уақыты сияқты қызметтермен өзара әрекеттесуіңіздің мәліметтері.</li>
                                    <li>Орын туралы ақпарат: IP мекенжайыңызды пайдаланып жалпы географиялық орныңызды анықтауымыз мүмкін.</li>
                                    <li>Құрылғы туралы ақпарат: Қызметтерімізге кіру үшін пайдаланатын құрылғы мен бағдарламалық құрал туралы ақпарат.</li>
                                </ul>
                                
                                <h4>Біз басқа көздерден алатын жеке ақпарат</h4>
                                <p>Paidax Limited сіз туралы ақпаратты басқа көздерден ала алады, соның ішінде:</p>
                                <ul>
                                    <li>Үшінші тарап серіктестері: жеке куәлікті тексеру мен алаяқтықты болдырмауды қосқанда, қызметтерімізді басқару және қолдау үшін үшінші тарап серіктестерінен алынған ақпарат.</li>
                                    <li>Қызмет көрсетушілер: біздің атымыздан ақпаратты жинайтын немесе беретін ұйымдар.</li>
                                </ul>
                                
                                <h4>Қолдану мақсаты</h4>
                                <p>Paidax Limited жиналған жеке ақпаратты әртүрлі мақсаттарда пайдаланады, соның ішінде:</p>
                                <ul>
                                    <li>Қызметтерді қамтамасыз ету: транзакцияларды жақсарту, төлем қауіпсіздігін қамтамасыз ету және жеке басын тексеруді қоса алғанда, қызметтерімізді сақтау, қамтамасыз ету және жеткізу.</li>
                                    <li>Іскерлік операциялар: шот ұсыну мен бухгалтерлік есепті қоса алғанда, бизнесті басқару.</li>
                                    <li>Қызметтерді жетілдіру, талдау, әзірлеу және зерттеу: Қызметтерді жақсарту, пайдаланушылардың өзара әрекеттесуін талдау, жаңа мүмкіндіктерді әзірлеу және ішкі операцияларды жақсарту үшін зерттеулер жүргізу.</li>
                                    <li>Клиенттерді қолдау: тұтынушыларға қолдау көрсету және сұрақтарға жауап беру.</li>
                                </ul>
                                
                                <h4>Жеке ақпаратты ашу</h4>
                                <p>Paidax Limited транзакцияларды аяқтау, қызметтерді көрсету немесе сіздің келісіміңіз бойынша қажет болған жағдайда жеке ақпаратты аша алады. Ақпаратты ашу мыналарды қамтиды:</p>
                                <ul>
                                    <li>Қызмет көрсетушілер: компания атынан әрекет ететін жеткізушілерге немесе агенттерге жеке ақпаратты беру.</li>
                                    <li>Қаржылық компаниялар және төлем өңдеушілері: төлемдерді өңдеу және алаяқтықтың алдын алу үшін банктерге және басқа ұйымдарға төлем және транзакция деректерін ашу.</li>
                                    <li>Корпоративтік мәмілелердің тараптары: бірігу, қаржыландыру, сатып алу, банкроттық, тарату немесе бизнесті немесе оның бір бөлігін беру контекстінде.</li>
                                    <li>Құқықтық және құқық қорғау қызметі: Қолданыстағы заңнаманы сақтау немесе заңды сұрауларға, соның ішінде басқа мемлекеттік органдардың сұрауларына жауап беру үшін қажет болғанда жеке ақпаратқа қол жеткізу, ашу және сақтау.</li>
                                    <li>Қоғамдық ақпарат: Белгілі бір ақпарат біздің қызметтеріміз арқылы көпшілікке ашылуы мүмкін.</li>
                                </ul>
                                
                                <h4>Cookie файлдары мен соған ұқсас технологиялардан алынған ақпарат</h4>
                                <p>Paidax Limited Қызметтерді басқару және деректерді, соның ішінде пайдалану деректерін, идентификаторларды және құрылғы ақпаратын жинау үшін cookie файлдарын, веб-белгілерді, мобильді аналитика идентификаторларын, жарнама идентификаторларын және ұқсас технологияларды пайдаланады.</p>
                                
                                <h4>Жеке ақпаратты қалай қорғаймыз</h4>
                                <p>Paidax Limited жеке ақпаратты рұқсатсыз кіруден, пайдаланудан, ашудан, өзгертуден және жоюдан қорғау үшін ақылға қонымды қадамдар жасайды. Пайдаланушыларға күшті құпия сөздерді пайдалануды және оларды үшінші тараптарға бөліспеуді ұсынамыз.</p>
                                
                                <h4>Жеке ақпаратты қалай сақтаймыз</h4>
                                <p>Жеке ақпарат Қызметтерді көрсету, транзакцияларды аяқтау, заңды міндеттемелерді орындау, дауларды шешу, келісімдерді орындау және басқа да заңды іскерлік мақсаттар үшін қажет болғанша сақталады. Сақтау мерзімдері тұтынушылардың күтулеріне, деректер сезімталдығына, деректерді жоюдың автоматтандырылған құралдарының қолжетімділігіне және заңды міндеттемелерге байланысты өзгеруі мүмкін.</p>
                                
                                <h4>Осы Құпиялық саясатындағы өзгертулер</h4>
                                <p>Осы Құпиялық саясаты Қызметтердегі, деректер тәжірибесіндегі немесе қолданыстағы заңдардағы өзгерістерді көрсету үшін мерзімді түрде жаңартылып отырады. «Күшіне ену күні» әр жаңартумен өзгереді. Біз пайдаланушыларға осы Құпиялық саясатын жүйелі түрде қарап шығуға шақырамыз.</p>
                                
                                <h4>Байланыс ақпараты</h4>
                                <p>Мәліметтерді өңдеу әрекеттеріне қатысты сұрақтар, пікірлер немесе алаңдаушылықтар болса, оны <a style={{ textDecoration: 'none', color: 'grey' }} href="mailto:info@paidax.kz"> info@paidax.kz</a> электрондық пошта мекен-жайына жолдаңыз. Заңды мекен-жайымыз: Астана қаласы, Есіл ауданы, 5, Достық, 125-офис, Z05H9M1.</p>
                            </Col>
                        </Container>
                    </div>
                </>
            )
        }
    
}

export default PrivacyPolicy