// import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Index from "./pages/Index";
// import Nav from "./components/Nav";
// import Footer from "./components/Footer";
// import SecondFooter from "./components/SecondFooter";
// import Legal from "./pages/Legal";
// import FAQ from "./pages/FAQ";
// import PrivacyPolicy from "./pages/PrivacyPolicy";

// function App() {
//   return (
//     <>
//       <BrowserRouter>
//         <Nav />
//         <main>
//           <Routes hashType="noslash">
//             <Route index element={<Index />} />
//             <Route path="/legal-center" element={<Legal />} />
//             <Route path="/faq" element={<FAQ />} />
//             <Route path="/privacy-policy" element={<PrivacyPolicy />} />
//           </Routes>
//         </main>
//         <Footer />
//         {/* <SecondFooter /> */}
//       </BrowserRouter>
//     </>
//   );
// }

// export default App;

import React from 'react';
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import queryString from 'query-string';
import Index from "./pages/Index";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import SecondFooter from "./components/SecondFooter";
import Legal from "./pages/Legal";
import FAQ from "./pages/FAQ";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Application from "./pages/Application";
import ApplicationShareWithoutReferral from "./pages/ApplicationShareWithoutReferral";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Nav />
        <main>
          <HashRoutes />
        </main>
        <Footer />
        <SecondFooter />
      </BrowserRouter>
    </>
  );
};

const HashRoutes = () => {
  const location = useLocation();

  const renderComponent = () => {
    switch (location.hash) {
      case '#legal-center':
        return <Legal />;
      case '#faq':
        return <FAQ />;
      case '#privacy-policy':
        return <PrivacyPolicy />;
      case '#app':
        return <Application />;
      case '#application':
        return <ApplicationShareWithoutReferral />;
      default:
        return <Index />;
    }
  };

  return (
    <Routes>
      <Route path="/" element={renderComponent()} />
    </Routes>
  );
};

export default App;

