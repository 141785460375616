import React from 'react';
import { useTranslation } from 'react-i18next';
import config from '../config'; 


const RedirectStore = () => {
    const { t } = useTranslation();
    const refferal = config.urls.itsRefferal;

    React.useEffect(() => {
        window.location.href = refferal;
       
    }, [refferal]);

    return (
        <div><p>{t('Redirecting')}...</p></div>
    );
};

export default RedirectStore;