import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import LanguageSwitcher from '../components/LanguageSwitcher'
import Accordion from 'react-bootstrap/Accordion';

const FAQ = () => {
    const { t } = useTranslation()

    const [list, setList] = useState(Array.from({ length: 15 }, (_, index) => index + 1))

    useEffect(() => {
    }, [])

    return (
        <div className="faq">
            <div className="content">
                <Container>
                    <div className="d-flex justify-content-between align-items-center">
                        <Link to="/" className="brand">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlSpace="preserve"
                                style={{
                                    shapeRendering: "geometricPrecision",
                                    textRendering: "geometricPrecision",
                                    fillRule: "evenodd",
                                    clipRule: "evenodd",
                                }}
                                viewBox="0 0 31750 31750"
                            >
                                <path
                                    d="m11567.97 16311.13 3789.6-709.17 919.54-2525.58 2747.49 2981.12c-383.74 121.32-5031.71 1024.47-5852.25 1190.41-698.73 141.31-1324.83 202.12-1833.65 557.45-520.49 363.5-629.05 713.81-860.14 1492.23-546.05 1773.6-1191.24 3625.26-1661.63 5410.09l3803.2-779.39c158-567.08 366.14-1123.6 551.15-1677.51 542.53-1624.42 262.75-1317.14 2760.9-1765.32 1243.77-223.15 4751.37-735.57 5474.45-1314.02 417.01-333.6 1230.39-2516.6 1405.64-3122.76 322.29-1114.64-1452.88-2846.16-1507.57-2914.36l-6256.44-7286.87-3480.29 10463.68z"
                                />
                            </svg>
                            {t('brandName')}
                        </Link>
                        <ul className="d-flex list-unstyled align-items-center mb-0">
                            <LanguageSwitcher themeStyle={"light"} />
                            <li className="ms-3">
                                <a href="#contact-us" className="btn btn-outline-light desktop-show">
                                    {t('ctaBtnText')}
                                </a>
                                <a href="#contact-us" className="btn btn-outline-light mobile-show">
                                    {t('ctaBtnLabel')}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <h1 className='text-white fw-bold my-5'>FAQ</h1>
                </Container>
            </div>

            <Container className='pt-5'>
                {
                    list.length > 0 && <Accordion defaultActiveKey="1">

                        {
                            list.map((item) => (
                                <Accordion.Item key={item} eventKey={item}>
                                    <Accordion.Header>{item +")"} {t("faq.question"+item)}</Accordion.Header>
                                    <Accordion.Body>
                                        {t("faq.answer"+item)}
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))
                        }

                    </Accordion>
                }
            </Container>
        </div>
    )
}

export default FAQ