import React from 'react'
import Banner from '../components/index/Banner'
import HowItWork from '../components/index/HowItWork'
import Calculator from '../components/index/Calculator'
import SwiperInstruction from '../components/index/SwiperInstruction'
import CreatePortfolio from '../components/index/CreatePortfolio'
import Questionary from '../components/index/Questionary'
import ContactUs from '../components/index/ContactUs'
import Partners from '../components/index/Partners'

function Index() {
  return (
    <>
      <Banner/>
      <Partners/>
      <SwiperInstruction/>
      <CreatePortfolio/>
      <HowItWork/>
      {/* <Calculator/> */}
      {/* <Advantages/> */}
      {/* <Safety/> */}
      {/* <ContactUs/> */}
      <Questionary/>
    </>
  )
}

export default Index