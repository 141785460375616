import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import config from '../config'; 

function Footer() {
  const { t } = useTranslation();
  const {appStoreUrl, playStoreUrl} = config.urls;

  return (
    <footer id="footer">
      <div className="footer-container">
        <Container>
          <h2 className="section-title">
            {t('footer.paidaxInvest')}
          </h2>
          <div style={{ height: '20px' }}></div>
          <Row>
          
            <Col className="d-flex flex-column justify-content-center align-items-center">

              <div className="btns">
                <img src="/images/qr-app.png" alt={t('footer.qrCodeAlt')} width={140} height={140} style={{ borderRadius: 10 }} />
              </div>
              <div className="footer-store-links" >
                <a href={appStoreUrl} target="_blank" rel="noopener noreferrer">
                  <img
                    src="/images/download-app-store.png"
                    alt="Download on the App Store"
                    width={'150px'}

                    // style={styles.storeBadge}
                  />
                </a>
                
              </div>
              <div className="footer-store-links">
              <a href={playStoreUrl} target="_blank" rel="noopener noreferrer">
                  <img
                    src="/images/download-google-play.png"
                    alt="Get it on Google Play"
                    width={'150px'}
                    // style={styles.storeBadge}
                  />
                </a>
              </div>
            </Col>
            {/* <Col>
            <div className="img">
            <img src="/images/app-mockup.png" alt="" />
          </div>
            </Col> */}
          </Row>
          <Container>
            <div style={{ height: '40px' }}></div>
          </Container>

          {/* <div className="btns">
            <img src="/images/qr-app.png" alt={t('footer.qrCodeAlt')} width={140} height={140} style={{ borderRadius: 10 }} />
          </div>
          <div className="btns" >
            <a href="" target="_blank" rel="noopener noreferrer">
              <img
                src="/images/download-app-store.png"
                alt="Download on the App Store"
                width={'150px'}

                // style={styles.storeBadge}
              />
            </a>
            <a href="" target="_blank" rel="noopener noreferrer">
              <img
                src="/images/download-google-play.png"
                alt="Get it on Google Play"
                width={'150px'}
                // style={styles.storeBadge}
              />
            </a>
          </div>
          <div className="img">
            <img src="/images/index-banner-phone-right.png" alt="" />
          </div> */}
        </Container>
      </div>
      <div className="footer-links-container">
        <Container>
          <Row>
            <Col>
              <Link to="/" className="brand">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlSpace="preserve"
                  style={{
                    shapeRendering: "geometricPrecision",
                    textRendering: "geometricPrecision",
                    fillRule: "evenodd",
                    clipRule: "evenodd",
                  }}
                  viewBox="0 0 31750 31750"
                >
                  <path
                    d="m11567.97 16311.13 3789.6-709.17 919.54-2525.58 2747.49 2981.12c-383.74 121.32-5031.71 1024.47-5852.25 1190.41-698.73 141.31-1324.83 202.12-1833.65 557.45-520.49 363.5-629.05 713.81-860.14 1492.23-546.05 1773.6-1191.24 3625.26-1661.63 5410.09l3803.2-779.39c158-567.08 366.14-1123.6 551.15-1677.51 542.53-1624.42 262.75-1317.14 2760.9-1765.32 1243.77-223.15 4751.37-735.57 5474.45-1314.02 417.01-333.6 1230.39-2516.6 1405.64-3122.76 322.29-1114.64-1452.88-2846.16-1507.57-2914.36l-6256.44-7286.87-3480.29 10463.68z"
                  />
                </svg>
                Paidax
              </Link>
              <a className="ms-4 d-block mt-1" style={{ textDecoration: 'none', color: 'grey' }} href="tel:+77776406060">
              + 7 777 640-60-60
              </a>
              <a className="ms-4 d-block mt-1" style={{ textDecoration: 'none', color: 'grey' }} href="mailto:info@paidax.kz">
                info@paidax.kz
              </a>
            </Col>
            <Col lg={3}>
              <h4 className="fw-bold mt-3 ms-2">{t('footer.address')}</h4>
              <p className="mb-1 ms-2">
                {t('footer.nurSultan')}, <br /> {t('footer.dostykAddress')}, <br />
                Z19Y9B2
              </p>
            </Col>
            <Col lg={2}>
              <h4>{t('footer.support')}</h4>
              <ul>
                <li>
                  <Link to="/#faq" onClick={() => window.scrollTo(0, 0)}>
                    <p>FAQ</p>
                  </Link>
                </li>
                <li>
                  <Link to="/#legal-center" onClick={() => window.scrollTo(0, 0)}>
                    <p>{t('footer.legalCenter')}</p>
                  </Link>
                </li>
              </ul>
            </Col>
            <Col lg={3}>
              <h4>{t('footer.socialMedia')}</h4>
              <ul className="socials">
                <li>
                  <a href="https://www.facebook.com/Paidax/">
                    <img src="/images/facebook.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/paidax.kz/">
                    <img src="/images/instagram.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/paidax">
                    <img src="/images/linkedin.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://www.tiktok.com/@paidax.kz">
                    <img src="/images/tiktok.png" alt="" />
                  </a>
                </li>
              </ul>
            </Col>
          </Row>

          <hr />

          <div className="d-flex justify-content-between copyright">
            <p>© {t('footer.copyright')} Paidax Limited. {t('footer.allRightsReserved')}</p>
            <ul className="privacy-ul">
              <li><a href="/#privacy-policy" target="_blank">{t('footer.privacyPolicy')}</a></li>
            </ul>
          </div>
        </Container>
      </div>
    </footer>
  );
}

export default observer(Footer);
