import React from 'react';
import { Container } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

function Questionary() {
    const { t } = useTranslation();

    return (
        <section id="questionary">
            <Container>
                <div className="querstionary-container">
                    <h3>{t('questionary.pageTitle')}</h3>
                    <button type="button" className="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        {t('questionary.startSurvey')}
                    </button>
                </div>
            </Container>
        </section>
    )
}

export default observer(Questionary);
