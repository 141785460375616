import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Context } from '..';

const LanguageSwitcher = ({ themeStyle }) => {
    const { i18n } = useTranslation();
    const { store } = useContext(Context);

    const onChangeLanguage = (language) => {
        i18n.changeLanguage(language);
    };

    return (
        <>
            <li>
                <button onClick={() => {
                    store.setLang('kz')
                    onChangeLanguage('kz')
                }} className={`lang-btn ${themeStyle} ${(store.is('kz') ? 'active' : '')}`}>
                    Қаз
                </button>
            </li>
            <li>
                <button onClick={() => {
                    store.setLang('ru')
                    onChangeLanguage('ru')
                }} className={`lang-btn ${themeStyle} ${(store.is('ru') ? 'active' : '')}`}>
                    Рус
                </button>
            </li>
            <li>
                <button onClick={() => {
                    store.setLang('en')
                    onChangeLanguage('en')
                }} className={`lang-btn ${themeStyle} ${(store.is('en') ? 'active' : '')}`}>
                    Eng
                </button>
            </li>
        </>
    );
};

export default observer(LanguageSwitcher);
