import { makeAutoObservable } from "mobx";

export default class Store {
    constructor() {
        this._lang = localStorage.getItem('i18nextLng') || 'ru'
        makeAutoObservable(this)
    }

    is(lang) {
        return this._lang === lang
    }

    get lang() {
        return this._lang
    }

    setLang(l) {
        this._lang = l
        localStorage.setItem('i18nextLng', l)
    }
}