import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function SecondFooter() {
    const { t } = useTranslation();

    return (
        <div id="second-footer">
            <Container>
                <p>{t('secondFooter.paragraph1')}(<a href="https://www.afsa.kz">www.afsa.kz</a>)</p>
                <p>{t('secondFooter.paragraph2')}</p>
                <p>{t('secondFooter.paragraph3')}</p>
                <p>{t('secondFooter.paragraph4')}</p>
            </Container>
        </div>
    );
}

export default SecondFooter;