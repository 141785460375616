import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { priceToString } from "../..";
import { observer } from "mobx-react-lite";
import { useTranslation } from 'react-i18next';
import axios from "axios";

function Calculator() {
  const { t } = useTranslation();


  const companies = [
    {
      symbol: 'SPY',
      title: 'S&P 500',
      icon: "/images/calculator-logo/s&p500.png",
      currentPrice: 214.01,
      years: {
        2020: 304.11,
        2021: 351.67,
        2022: 461.61,
        2023: 373.96,
        2024: 373.96
      }
    },
    {
      symbol: 'TSLA',
      title: 'Tesla',
      icon: "/images/calculator-logo/tesla.png",
      currentPrice: 214.01,
      years: {
        2020: 28.68,
        2021: 243.26,
        2022: 399.93,
        2023: 108.10,
        2024: 248.42
      }
    },
    {
      symbol: 'META',
      title: 'Meta',
      icon: "/images/calculator-logo/meta.png",
      currentPrice: 214.01,
      years: {
        2020: 209.56,
        2021: 268.65,
        2022: 338.18,
        2023: 124.61,
        2024: 345.92
      }
    },
    {
      symbol: 'MSFT',
      title: 'Microsoft',
      icon: "/images/calculator-logo/ms.png",
      currentPrice: 214.01,
      years: {
        2020: 154.49,
        2021: 211.61,
        2022: 328.12,
        2023: 237.04,
        2024: 370.19
      }
    },
    {
      symbol: 'GOOG',
      title: 'Alphabet Inc.',
      icon: "/images/calculator-logo/google.png",
      currentPrice: 214.01,
      years: {
        2020: 68.37,
        2021: 86.41,
        2022: 145.07,
        2023: 89.70,
        2024: 139.56
      }
    }
  ]

  useEffect(() => {
    axios.get('http://34.38.193.38/v1/assets-by-common-flag?ids=08593542-b265-4192-a508-737973ed25a0&ids=458f64a2-3e72-40df-82cf-5d80d47cf7a0&ids=8347ab4a-fbb2-4523-a0f2-a26966c08b12&ids=1e05139e-3e72-45bb-95ef-7354d065354c&ids=ab311972-39ca-45b9-b7a4-988f481c452f&ids=41cb0723-5eaa-4664-b094-1b79257a4f56&parts=5', {
      // withCredentials: true
    }).then((response) => {

      var data = Array.from(response.data).map((company) => ({
        symbol: company.basic.symbol,
        currentPrice: company.ticker.currentPrice ?? company.ticker.prevDailyClosePrice
      }))

      data.forEach((d) => {
        for (let item of companies) {
          if (item.symbol == d.symbol) {
            item.currentPrice = d.currentPrice
          }
        }
      })

    }).catch((e) => {
      console.log(e)
    })
  }, [])


  const [changePercent, setChangePercent] = useState(1);

  const [selectedCompany, setSelectedCompany] = useState(companies[0].symbol)
  const [selectedYear, setSelectedYear] = useState('2020')

  const [investedMoney, setInvestedMoney] = useState(100);
  const [profit, setProfit] = useState(0);


  useEffect(() => {
    const currentPrice = companies.filter(m => m.symbol === selectedCompany)[0].currentPrice
    const targetPrice = companies.filter(m => m.symbol === selectedCompany)[0].years[selectedYear]
    const diff = (currentPrice - targetPrice) / targetPrice
    setProfit(Math.round(diff * investedMoney))
    setChangePercent((diff * 100).toFixed(2))
  }, [selectedCompany, selectedYear, investedMoney])



  return (
    <section id="index-calculator">
      <Container>
        <h2 className="section-title">{t('calculatorTitle')}</h2>

        <div className="logos">
          {
            companies.map((company) => {
              return <img
                src={company.icon}
                className={"logo " + (selectedCompany === company.symbol && "active")}
                onClick={() => {
                  setSelectedCompany(company.symbol)
                }}
                key={company.symbol}
                alt={company.title}
              />
            })
          }
        </div>

        <div className="years">
          {
            Object.entries(companies[0].years).map(([key, value]) => {
              return <Button
                key={key}
                variant={key === selectedYear ? "primary" : "outline-primary"}
                onClick={() => {
                  setSelectedYear(key)
                }}
              >{key}</Button>
            })
          }
        </div>


        <div className="statusBar">
          <div className="d-flex justify-content-between">
            <p>{t('investedMoneyText')}</p>
            <p>${investedMoney}</p>
          </div>
          <div className="position-relative">
            <div className="rangeLine" style={{
              width: `calc(${(investedMoney / 10)}% - 5px)`
            }}>

            </div>
            <input
              type="range"
              min={1}
              max={1000}
              value={investedMoney}
              onChange={(e) => {
                setInvestedMoney(+e.target.value);
              }}
              className="form-range"
            />
          </div>
        </div>

        <div className="d-flex flex-wrap justify-content-between mx-auto" style={{ maxWidth: 600 }}>
          <div className="win me-1">
            <p>{t('earningsText')}</p>
            <h3>
              $ ({investedMoney}
              {profit > 0 ? '+' : '-'}
              <span className={profit > 0 ? 'text-success' : 'text-danger'}>{priceToString(Math.abs(profit))}</span>)
            </h3>
            <h5>
              <svg
                viewBox="0 0 13 12"
                width={16}
                height={16}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="h-3 me-2"
              >
                <path
                  d="M1 10.84L11.241 1M1 1h10.241v9.84"
                  stroke="#5750fa"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
              {changePercent}%
            </h5>
          </div>
          {/* <div className="win">
            <p>{t('usdDepositText')}</p>
            <h3>
              $
              {priceToString(
                investedMoney +
                parseInt(
                  investedMoney *
                  0.01 *
                  (["2019", "2020"].includes(years.filter((m) => m.id === selectedYear)[0].title) ? 2 : 1)
                )
              )}
            </h3>
            <h5>
              {(["2019", "2020"].includes(years.filter((m) => m.id === selectedYear)[0].title) ? 2 : 1)}%
            </h5>
          </div> */}
        </div>
      </Container>
    </section>
  );
}

export default observer(Calculator);
