// src/config.js
const config = {
    urls: {
        appStoreUrl: 'https://apps.apple.com/kz/app/paidax-invest-made-easy/id6502937850',
        playStoreUrl: 'https://play.google.com/store/apps/details?id=kz.paidax.brokerage',
        defaultUrl: 'https://paidax.kz',
        itsRefferal: 'https://paidax.page.link/its-conf-refer'
    }
};

export default config;
